import { useState } from "react";
import { useParams } from "react-router-dom";
import AboutGuide from "../../Components/AboutGuide";
import styles from "../../Styles/Homepage.module.scss";

function Homepage() {
  const params = useParams();

  let dates = "";
  console.log(params.trip);

  switch (params.trip) {
    case "June-2025":
      dates = "June 9 - 20, 2025";
      break;
    case "August_2-13-2025":
      dates = "August 2 - 13, 2025";
      break;
    case "August_16-27-2025":
      dates = "August 16 - 27, 2025";
      break;
  }

  return (
    <div className={styles.backgroundPictureSwitz}>
      <div className={styles.titleBox}>
        <h1>
          Come to what many consider to be the most beautiful country in the
          world,
        </h1>
        <p className={styles.title}>Switzerland</p>
        <p className={styles.dates}>{dates}</p>
        <div className={styles.description}>
          <p>
            Zurich, Lucerne, Lake Lucerne, Mount Pilatus, Lauterbrunnen and
            Murren in the Alps:{" "}
          </p>
          <p>
            Switzerland's spectacular landscape includes snow-capped Alps and
            grassy hills spotted with grazing cows. It is home to 1,500
            sparkling, crystal-clear lakes.
          </p>
        </div>
        <div>
          <AboutGuide />
        </div>
      </div>
    </div>
  );
}

export default Homepage;
