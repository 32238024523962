import { useState } from "react";
import { useParams } from "react-router-dom";
import styles from "../../Styles/Itinerary.module.scss";
// import picture_2 from "../../img/Greece/Day 2 Athens.jpeg";
// import picture_3 from "../../img/Greece/Day 3 Athens.jpeg";
// import picture_4 from "../../img/Greece/Day 4 Mt. Olympas.jpeg";
// import picture_5 from "../../img/Greece/Day 5 Thessalonika.jpeg";
// import picture_6 from "../../img/Greece/Day 6 Kavala.jpeg";
// import picture_7 from "../../img/Greece/Day 7 Philippi.jpeg";
// import picture_8 from "../../img/Greece/Day 8 Berea.jpeg";
// import picture_9 from "../../img/Greece/Day 9 Meteora.jpeg";
// import picture_10 from "../../img/Greece/Day 10 Actium.jpeg";
// import picture_11 from "../../img/Greece/Day 11 Corinth.jpeg";
import itineraryOne from "../../img/Switzerland/Itinerary_Swiss_Trip_June_9-20.pdf";
import itineraryTwo from "../../img/Switzerland/Itinerary_Swiss_Trip_August_2-13.pdf";
import itineraryThree from "../../img/Switzerland/Itinerary_Swiss_Trip_August_16-27.pdf";
import { IoClose } from "react-icons/io5";
import pictureOne from "./../../img/Switzerland/SwitzItin_1.jpeg";
import pictureTwo from "./../../img/Switzerland/SwitzItin_2.jpeg";
import pictureThree from "./../../img/Switzerland/SwitzItin_3.jpg";

function ItalyItinerary() {
  //For day 2

  const [boxState, setBoxState] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const open = function (i) {
    const copy = [...boxState];
    copy[i] = true;
    setBoxState(copy);
  };
  const close = function (i) {
    const copy = [...boxState];
    copy[i] = false;
    setBoxState(copy);
  };

  const tripOne = [
    {
      day: "Day 1, Saturday, June 9",
      text: "Depart the USA. Fly to the Zurich Airport.",
    },
    {
      day: "Day 2, Sunday, June 10",
      text: "Upon arrival to Switzerland, we will be staying in a hotel right at the airport. What is left of the day will be spent in resting, eating and visiting (staying in Zurich).",
    },
    {
      day: "Day 3, Monday, June 11",
      text: "Today we will take a tour of Zurich which will include going through the Grossmunster, pausing at the site where Felix Manz was drowned in the Limat River, walking into the former home of Conrad Grebel, cruising on Lake Zurich and so much more.  In late afternoon we will drive to the beautiful city of Lucerne (overnight in Lucerne).",
    },
    {
      day: "Day 4, Tuesday, June 12",
      text: "We will discover the spectacular natural beauty of the Swiss Alps during this action-packed day trip from Lucerne to the top of Mt. Pilatus. We will take a panoramic gondola ride, a cogwheel train ride (the steepest in the world), and a cruise on Lake Lucerne making this an exceptional day (overnight in Lucerne).",
    },
    {
      day: "Day 5, Wednesday, June 13",
      text: "After breakfast we will travel to the region of Emmental. Here we will stop at the Fankhauser barn located near the town of Trub that has the famous “Täuferversteck” (Anabaptist Hideout). The barn has a trapdoor leading to the hidden chamber home which was a hiding place from the authorities. Afterward we will travel to Schangnau a town nestled in a beautiful valley where Anabaptists and Jews lived and where Dan Glick’s ancestors originated. Toward evening we will travel on to our hotel near Thun (overnight near Thun).",
    },
    {
      day: "Day 6, Thursday, June 14",
      text: "In the morning we will tour the Thun Castle, a place where some Anabaptists were imprisoned. Following that tour, we will drive alongside Lake Thun to Interlaken and then on to Lauterbrunnen in the heart of the Alps. After grabbing some lunch and viewing the gorgeous falls at Lauterbrunnen, we will park in the valley and take a cable car (no cars can access this place) to the village of Murren in the Alps. We will stay in this village with its absolutely incredible views over the next couple days (overnight in Murren).",
    },
    {
      day: "Day 7-8, Friday-Saturday, June 15-16",
      text: "These two relaxing days will be filled with hikes, and if you desire, an incredible cable car ride that takes you from the mountain village of Mürren to Birg station at 8,782 feet above sea level, where you change into another cable car that takes you to the Schilthorn's Piz Gloria restaurant at 9,744 feet above sea level. Prepare for astounding views and time to spend just taking in some of the most beautiful sights in this world (staying in Murren).",
    },
    {
      day: "Day 9, Sunday, June 17",
      text: "Taking a very scenic route, we will drive leisurely to eastern Switzerland, making several stops along the way. One stop will be at the Tauferhole (Anabaptist Cave) which was the meeting place for local Anabaptists to worship undetected by the authorities hunting for them. In the evening, we will arrive to our beautiful accommodations where we will lodge for the next two nights near Appenzell, which is the most traditional Swiss area. (staying in Appenzell).",
    },
    {
      day: "Day 10, Monday, June 18",
      text: "Today we take the cable car up Ebenalp, a beautiful mountain with outstanding views and hiking trails just south of Appenzell. We will eat at an outdoor restaurant right on the cliff overlooking the mountains and the valley below. Later in the day, we will spend time in the town of Appenzell (staying in Appenzell).",
    },
    {
      day: "Day 11, Tuesday, June 19",
      text: "Our day will include a leisurely drive back to Zurich with stops on the way at a Swiss cheese factory and a chocolate factory. By evening we will arrive back at the hotel near the airport (staying in Zurich).",
    },
    {
      day: "Day 12, Wednesday, June 20",
      text: "Fly back to the USA",
    },
  ];

  const tripTwo = [
    {
      day: "Day 1, Saturday, August 2",
      text: "Depart the USA. Fly to the Zurich Airport.",
    },
    {
      day: "Day 2, Sunday, August 3",
      text: "Upon arrival to Switzerland, we will be staying in a hotel right at the airport. What is left of the day will be spent in resting, eating and visiting (staying in Zurich).",
    },
    {
      day: "Day 3, Monday, August 4",
      text: "Today we will take a tour of Zurich which will include going through the Grossmunster, pausing at the site where Felix Manz was drowned in the Limat River, walking into the former home of Conrad Grebel, cruising on Lake Zurich and so much more.  In late afternoon we will drive to the beautiful city of Lucerne (overnight in Lucerne).",
    },
    {
      day: "Day 4, Tuesday, August 5",
      text: "We will discover the spectacular natural beauty of the Swiss Alps during this action-packed day trip from Lucerne to the top of Mt. Pilatus. We will take a panoramic gondola ride, a cogwheel train ride (the steepest in the world), and a cruise on Lake Lucerne making this an exceptional day (overnight in Lucerne).",
    },
    {
      day: "Day 5, Wednesday, August 6",
      text: "After breakfast we will travel to the region of Emmental. Here we will stop at the Fankhauser barn located near the town of Trub that has the famous “Täuferversteck” (Anabaptist Hideout). The barn has a trapdoor leading to the hidden chamber home which was a hiding place from the authorities. Afterward we will travel to Schangnau a town nestled in a beautiful valley where Anabaptists and Jews lived and where Dan Glick’s ancestors originated. Toward evening we will travel on to our hotel near Thun (overnight near Thun).",
    },
    {
      day: "Day 6, Thursday, August 7",
      text: "In the morning we will tour the Thun Castle, a place where some Anabaptists were imprisoned. Following that tour, we will drive alongside Lake Thun to Interlaken and then on to Lauterbrunnen in the heart of the Alps. After grabbing some lunch and viewing the gorgeous falls at Lauterbrunnen, we will park in the valley and take a cable car (no cars can access this place) to the village of Murren in the Alps. We will stay in this village with its absolutely incredible views over the next couple days (overnight in Murren).",
    },
    {
      day: "Day 7-8, Friday-Saturday, August 8-9",
      text: "These two relaxing days will be filled with hikes, and if you desire, an incredible cable car ride that takes you from the mountain village of Mürren to Birg station at 8,782 feet above sea level, where you change into another cable car that takes you to the Schilthorn's Piz Gloria restaurant at 9,744 feet above sea level. Prepare for astounding views and time to spend just taking in some of the most beautiful sights in this world (staying in Murren).",
    },
    {
      day: "Day 9, Sunday, August 10",
      text: "Taking a very scenic route, we will drive leisurely to eastern Switzerland, making several stops along the way. One stop will be at the Tauferhole (Anabaptist Cave) which was the meeting place for local Anabaptists to worship undetected by the authorities hunting for them. In the evening, we will arrive to our beautiful accommodations where we will lodge for the next two nights near Appenzell, which is the most traditional Swiss area. (staying in Appenzell).",
    },
    {
      day: "Day 10, Monday, August 11",
      text: "Today we take the cable car up Ebenalp, a beautiful mountain with outstanding views and hiking trails just south of Appenzell. We will eat at an outdoor restaurant right on the cliff overlooking the mountains and the valley below. Later in the day, we will spend time in the town of Appenzell (staying in Appenzell).",
    },
    {
      day: "Day 11, Tuesday, August 12",
      text: "Our day will include a leisurely drive back to Zurich with stops on the way at a Swiss cheese factory and a chocolate factory. By evening we will arrive back at the hotel near the airport (staying in Zurich).",
    },
    {
      day: "Day 12, Wednesday, August 13",
      text: "Fly back to the USA",
    },
  ];

  const tripThree = [
    {
      day: "Day 1, Saturday, August 16",
      text: "Depart the USA. Fly to the Zurich Airport.",
    },
    {
      day: "Day 2, Sunday, August 17",
      text: "Upon arrival to Switzerland, we will be staying in a hotel right at the airport. What is left of the day will be spent in resting, eating and visiting (staying in Zurich).",
    },
    {
      day: "Day 3, Monday, August 18",
      text: "Today we will take a tour of Zurich which will include going through the Grossmunster, pausing at the site where Felix Manz was drowned in the Limat River, walking into the former home of Conrad Grebel, cruising on Lake Zurich and so much more.  In late afternoon we will drive to the beautiful city of Lucerne (overnight in Lucerne).",
    },
    {
      day: "Day 4, Tuesday, August 19",
      text: "We will discover the spectacular natural beauty of the Swiss Alps during this action-packed day trip from Lucerne to the top of Mt. Pilatus. We will take a panoramic gondola ride, a cogwheel train ride (the steepest in the world), and a cruise on Lake Lucerne making this an exceptional day (overnight in Lucerne).",
    },
    {
      day: "Day 5, Wednesday, August 20",
      text: "After breakfast we will travel to the region of Emmental. Here we will stop at the Fankhauser barn located near the town of Trub that has the famous “Täuferversteck” (Anabaptist Hideout). The barn has a trapdoor leading to the hidden chamber home which was a hiding place from the authorities. Afterward we will travel to Schangnau, a town nestled in a beautiful valley where Anabaptists and Jews lived and where Dan Glick’s ancestors originated. Toward evening we will travel to Bern (overnight near Bern).",
    },
    {
      day: "Day 6, Thursday, August 21",
      text: "Today we will take a tour of the Bern Castle (where Anabaptists were imprisoned) and other places of interest around the city (overnight in Bern).",
    },
    {
      day: "Day 7, Friday, August 22",
      text: "After driving alongside Lake Thun to Interlaken and then on to Lauterbrunnen in the heart of the Alps, we will eat lunch and view the gorgeous falls at Lauterbrunnen. Following this stop we will park at the far end of the Lauterbrunnen Valley and take a cable car (no cars can access this place) to the village of Murren in the Alps. We will stay in this village with its absolutely incredible views (overnight in Murren).",
    },
    {
      day: "Day 8, Saturday, August 23",
      text: "This day will be filled with hikes, and if you desire, an incredible cable car ride that takes you from the mountain village of Mürren to Birg station at 8,782 feet above sea level, where you change into another cable car that takes you to the Schilthorn's Piz Gloria restaurant at 9,744 feet above sea level. Prepare for astounding views and time to spend just taking in some of the most beautiful sights in this world (staying in Murren).",
    },
    {
      day: "Day 9, Sunday, August 24",
      text: "Taking a very scenic route, we will drive leisurely to eastern Switzerland, making several stops along the way. One stop will be at the Tauferhole (Anabaptist Cave) which was the meeting place for local Anabaptists to worship undetected by the authorities hunting for them. In the evening, we will arrive to our beautiful accommodations where we will lodge for the next two nights near Appenzell, which is the most traditional Swiss area. (staying in Appenzell).",
    },
    {
      day: "Day 10, Monday, August 25",
      text: "Today we take the cable car up Ebenalp, a beautiful mountain with outstanding views and hiking trails just south of Appenzell. We will eat at an outdoor restaurant right on the cliff overlooking the mountains and the valley below. Later in the day, we will spend time in the town of Appenzell (staying in Appenzell).",
    },
    {
      day: "Day 11, Tuesday, August 26",
      text: "Our day will include a leisurely drive back to Zurich with stops on the way at a Swiss cheese factory and a chocolate factory. By evening we will arrive back at the hotel near the airport (staying in Zurich).",
    },
    {
      day: "Day 12, Wednesday, August 27",
      text: "Fly back to the USA",
    },
  ];

  const params = useParams();

  let dates = "";
  let itinerary = "";
  let trip = [];
  console.log(params.trip);

  switch (params.trip) {
    case "June-2025":
      dates = "June 9 - 20, 2025";
      itinerary = itineraryOne;
      trip = tripOne;
      break;
    case "August_2-13-2025":
      dates = "August 2 - 13, 2025";
      itinerary = itineraryTwo;
      trip = tripTwo;
      break;
    case "August_16-27-2025":
      dates = "August 16 - 27, 2025";
      itinerary = itineraryThree;
      trip = tripThree;
      break;
  }

  return (
    <div className="pageContainer">
      <p className="title">Itinerary</p>
      <p className="secondaryTitle">{dates}</p>
      <div className="printBox">
        <div className="print">
          <a href={itinerary} target="_blank" rel="noreferrer">
            Print PDF
          </a>
        </div>
      </div>
      <p className="text">
        This 10-day trip to Switzerland will take you to Zurich, Lucerne,
        Schangnau, Lauterbrunnen, Murren, and Appenzell. Ride to the top of Mt.
        Pilatus and boat on Lake Lucerne. Gondolas, cogwheel trains, and cable
        cars will take you to vistas with mind-blowing views in the heart of the
        Swiss Alps. Learn about the Swiss Reformation, eat traditional Swiss
        food, and enjoy the fellowship of a small tour group.{" "}
      </p>
      <div className="pictureRow">
        <div>
          <img className="picture" src={pictureOne} alt="Poland town" />
        </div>
        <div className="hide">
          <img className="picture" src={pictureTwo} alt="Poland solt mines" />
        </div>
        <div>
          <img className="picture" src={pictureThree} alt="Polamd mountains" />
        </div>
      </div>

      {trip.map((item) => (
        <div>
          <h2 className={styles.dayNumber}>{item.day}</h2>
          <p className="text">{item.text}</p>
        </div>
      ))}
    </div>
  );
}

export default ItalyItinerary;
