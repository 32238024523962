import styles from "./Footer.module.scss";

function Footer() {
  const tribe = {
    href: "https://www.tribeofdantours.com/testimonials/",
    target: "_blank",
  };
  const call = {
    href: "tell: +15133288852",
  };
  const facebook = {
    href: "https://www.facebook.com/danrglick",
    target: "_blank",
  };
  return (
    <div className={styles.footer}>
      <div className={styles.container}>
        {/* <h1 className="title">Our toure leader</h1> */}
        <div>
          <p>
            Read feedback and testimonies:
            <a {...tribe} rel="noreferrer">
              TribeofDanTours.com
            </a>
          </p>
        </div>
        <div>
          <p>
            Contact me at:{" "}
            <span className={styles.email}>danglick62@gmail.com</span>
          </p>
        </div>
        <div>
          <p>
            Call me:
            <a {...call} rel="noreferrer">
              513 328 8852
            </a>
          </p>
          {/* <p>
            Tel: <span className={styles.email}>513 328 8852</span>
          </p> */}
        </div>
        <div>
          <p>
            Visit my page on Facebook :
            <a {...facebook} rel="noreferrer">
              Facebook.com/danrglick
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
