import AboutGuide from "../../Components/AboutGuide";
import styles from "../../Styles/Homepage.module.scss";

function Homepage() {
  return (
    <div className={styles.backgroundPictureGermany}>
      <div className={styles.titleBox}>
        <h1 className={styles.intro}>
          Come and explore the history of the events that changed the world.
        </h1>
        <p className={styles.title}>Germany</p>
        <p className={styles.dates}>May 26 - June 6, 2025</p>
        <div className={styles.description}>
          <p>
            Berlin, Wittenberg, the Castle of Wartburg, Erfurt, Augsburg, the
            beauty of the Bavarian Alps, and much more.{" "}
          </p>
          <p>
            Discover the significant places and history of Martin Luther's life
            and the Protestant Reformation. Visit the home of Dietrich
            Bonhoeffer and the concentration camp where he was executed.
          </p>
        </div>
        <div>
          <AboutGuide />
        </div>
      </div>
    </div>
  );
}

export default Homepage;
