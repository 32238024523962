import React, { useState } from "react";
import styles from "./../../Styles/VideoStudy.module.scss";
import VideoBox from "../../Components/VideoBox";

//Germany
const videoList_1 = [
  {
    id: 1,
    title: "Full History of Germany in 5 Minutes",
    duration: "5:51 mins",
    sourceLink: "https://www.youtube.com/embed/GBbUmiH23-0?si=NpdXPiaz9prKbIpb",
  },
  {
    id: 2,
    title: "History of Germany",
    duration: "59:20 mins",
    sourceLink: "https://www.youtube.com/embed/5i9PpE8yu2s?si=lenIrlVMRafJlOgc",
  },
];
//Berlin
const videoList_2 = [
  {
    id: 1,
    title: "How the Berlin Wall Worked",
    duration: "12:11 mins",
    sourceLink: "https://www.youtube.com/embed/KoJ0Pih0Ssc?si=4e6MFSCOcadKLkvx",
  },
  {
    id: 2,
    title: "The BRUTAL Fall of Berlin",
    duration: "10:21 mins",
    sourceLink: "https://www.youtube.com/embed/BjvsuV28ryg?si=eJQJ69x-rYfSkSmu",
  },
  {
    id: 3,
    title: "Checkpoint Charlie - Berlin's Cold War Frontier",
    duration: "6:14 mins",
    sourceLink: "https://www.youtube.com/embed/bCquI4GOoF4?si=6BJY9Eyor-lQvrIh",
  },
  {
    id: 4,
    title:
      "Life Inside The Führerbunker: Adolf Hitler’s Paranoid Final Few Day",
    duration: "20:45 mins",
    sourceLink: "https://www.youtube.com/embed/42-CUHGkIgU?si=y-ehJCV2p4JFrhaQ",
  },
];

//Martin Luther and Reformation
const videoList_3 = [
  {
    id: 1,
    title:
      "Martin Luther, the Reformation and the nation | DW Documentary (42:50 mins)",
    duration: "42:50 mins",
    sourceLink: "https://www.youtube.com/embed/1qeRj_qfNM0?si=Pn1UCbFHEeZOeDn_",
  },
  {
    id: 2,
    title: "Rick Steves on Luther and the Reformation (55:37 mins)",
    duration: "55:37 mins",
    sourceLink: "https://www.youtube.com/embed/CXK9NNp1yk4?si=C1DDBDqHTWHAZ-U9",
  },

  {
    id: 3,
    title: "Why did the Protestant Reformation Happen?",
    duration: "11:58 mins",
    sourceLink: "https://www.youtube.com/embed/cXYyIBdBubE?si=1LKfUYsnoDIcUS5a",
  },
  {
    id: 4,
    title: "Martin Luther - PBS documentary",
    duration: "1:48:29 mins",
    sourceLink: "https://www.youtube.com/embed/8QNSzPREln0?si=XjIyZ3815GhFOssR",
  },
  {
    id: 5,
    title: "The Reformation",
    duration: "1:00:12 mins",
    sourceLink: "https://www.youtube.com/embed/8hXSarQeB3c?si=8JgJ73Wl2CeSU1XR",
  },
];
//Dietrich Bonhoeffer

const videoList_4 = [
  {
    id: 1,
    title: "Bonhoeffer: Pastor, Martyr, Prophet, Spy",
    duration: "26:46 mins",
    sourceLink: "https://www.youtube.com/embed/xpeNuz4FqX8?si=hnEGjJvrEW4CjIsh",
  },
  {
    id: 2,
    title: "Dietrich Bonhoeffer: A Story of Courage and Faith",
    duration: "28:30 mins",
    sourceLink: "https://www.youtube.com/embed/bCqk-vG31wI?si=fUO5C6MM2IWsC-9O",
  },
  {
    id: 3,
    title: "Bonhoeffer: Agent Of Grace (2000) | Full Movie",
    duration: "1:26:12 mins",
    sourceLink: "https://www.youtube.com/embed/2325_APzy6c?si=hQkz1hBt3MvzkTli",
  },
  {
    id: 4,
    title:
      "The Cost of Discipleship - Dietrich Bonhoeffer (quotes from his book).",
    duration: "5:48 mins",
    sourceLink: "https://www.youtube.com/embed/tI-4CFwBm4A?si=-UsomRtrF3WWHPg2",
  },
  {
    id: 5,
    title:
      "Who Am I by Dietrich Bonhoeffer. A poem written by Bonhoeffer in prison",
    duration: "2:27 mins",
    sourceLink: "https://www.youtube.com/embed/EjqnZQhqIYA?si=fgMC6U4U8dGnpYGW",
  },
];
//Bavaria
const videoList_5 = [
  {
    id: 1,
    title: "A Brief History of BAVARIA (Germany's Southern Catholic Kingdom)",
    duration: "12:09 mins",
    sourceLink: "https://www.youtube.com/embed/L8vXXMjhpPs?si=xq0sL_XIiFiqQ9vH",
  },
  {
    id: 2,
    title: "The Magical Alpine Town Most Tourists Miss | Mittenwald, Germany",
    duration: "16:32 mins",
    sourceLink: "https://www.youtube.com/embed/ytUbz1NFLg8?si=SGK9CVNvUcnzJPcP",
  },
];

function VideoStudy() {
  const [modal, setModal] = useState(false);
  const [videoLink, setVideoLink] = useState("");
  //const [videoLoading, setVideoLoading] = useState(true);

  const closeModal = () => {
    setModal(!modal);
  };

  return (
    <div className="pageContainer">
      <div className="title">Germany tour: documentaries</div>
      {/* <p className="text">You are invited/encouraged to watch these documentaries in the months prior to departure. It will add greatly to your experience/learning. I do not endorse everything said in these documentaries, but for the most part, I do.
      </p> */}
      <div className={`secondaryTitle ${styles.videoGroupTitle}`}>Germany</div>

      {videoList_1.map((item, i) => (
        <button
          onClick={() => {
            setModal(!modal);
            setVideoLink(item.sourceLink);
          }}
          className={styles.videoTitle}
          key={i}
        >
          {item.title}
          <p>{item.duration}</p>
        </button>
      ))}

      <div className={`secondaryTitle ${styles.videoGroupTitle}`}>Berlin</div>

      {videoList_2.map((item, i) => (
        <button
          onClick={() => {
            setModal(!modal);
            setVideoLink(item.sourceLink);
          }}
          className={styles.videoTitle}
          key={i}
        >
          {item.title}
          <p>{item.duration}</p>
        </button>
      ))}

      {modal ? (
        <VideoBox closeModal={closeModal} videoLink={videoLink} />
      ) : null}

      <div className={`secondaryTitle ${styles.videoGroupTitle}`}>
        Martin Luther and the Reformation{" "}
      </div>

      {videoList_3.map((item, i) => (
        <button
          onClick={() => {
            setModal(!modal);
            setVideoLink(item.sourceLink);
          }}
          className={styles.videoTitle}
          key={i}
        >
          {item.title}
          <p>{item.duration}</p>
        </button>
      ))}

      {modal ? (
        <VideoBox closeModal={closeModal} videoLink={videoLink} />
      ) : null}

      <div className={`secondaryTitle ${styles.videoGroupTitle}`}>
        Dietrich Bonhoeffer{" "}
      </div>
      {videoList_4.map((item, i) => (
        <button
          onClick={() => {
            setModal(!modal);
            setVideoLink(item.sourceLink);
          }}
          className={styles.videoTitle}
          key={i}
        >
          {item.title}
          <p>{item.duration}</p>
        </button>
      ))}

      {modal ? (
        <VideoBox closeModal={closeModal} videoLink={videoLink} />
      ) : null}
      <div className={`secondaryTitle ${styles.videoGroupTitle}`}>Bavaria </div>
      {videoList_5.map((item, i) => (
        <button
          onClick={() => {
            setModal(!modal);
            setVideoLink(item.sourceLink);
          }}
          className={styles.videoTitle}
          key={i}
        >
          {item.title}
          <p>{item.duration}</p>
        </button>
      ))}

      {modal ? (
        <VideoBox closeModal={closeModal} videoLink={videoLink} />
      ) : null}

      <h2 className={`secondaryTitle ${styles.subtitle}`}>
        Recommended Reading Materials:
      </h2>
      <ul className={styles.bookList}>
        <li className="text">
          <span>
            Martin Luther: The Man Who Rediscovered God and Changed the World.
            October 3, 2017,
          </span>{" "}
          by Eric Metaxas
        </li>
        <li className="text">
          <span>Bonhoeffer: Pastor, Martyr, Prophet, Spy. August 1, 2011,</span>{" "}
          by Eric Metaxas
        </li>
        <li className="text">
          <span>Letter to the American Church. September 20, 2022,</span> by
          Eric Metaxas
        </li>
      </ul>
    </div>
  );
}

export default VideoStudy;
