import React, { useState } from "react";
import styles from "./../../Styles/VideoStudy.module.scss";
import { IoCloseOutline } from "react-icons/io5";
import { BiLoaderAlt } from "react-icons/bi";
import VideoBox from "../../Components/VideoBox";

const videoList_1 = [
  {
    id: 1,
    title: "Greece - Geography, Economy & Culture",
    duration: "22:48 mins",
    sourceLink: "https://www.youtube.com/embed/RtNruJYaHHo?si=J5w3teyJkBRAdBRE",
  },
  {
    id: 2,
    title: "Geography Now! Greece",
    duration: "13:46 mins",
    sourceLink: "https://www.youtube.com/embed/Cp3yabqF4Uw?si=ogaT6DRCEMGE5ns2",
  },
];
const videoList_2 = [
  {
    id: 1,
    title: "The Eight Ages of Greece - A Complete History",
    duration: "57:05 mins",
    sourceLink: "https://www.youtube.com/embed/Mk-OyRI7c7Q?si=ghMR7llI7UoAa1UV",
  },
  {
    id: 2,
    title: "How Athenian Democracy Was Born - Ancient Greece DOCUMENTARY",
    duration: "20:00 mins",
    sourceLink: "https://www.youtube.com/embed/Amu6zhlCJO0?si=iglDy39JnU0iSAtt",
  },
  {
    id: 3,
    title: "History of Ideas – Ancient Greece",
    duration: "10:55 mins",
    sourceLink: "https://www.youtube.com/embed/kix2L1j2cDc?si=iwlmNm1gxtmv7LAF",
  },
  {
    id: 4,
    title: "The Greek gods explained",
    duration: "13:37 mins",
    sourceLink: "https://www.youtube.com/embed/y83J2_yiJD4?si=1V3tNL3V0KdgyeKU",
  },
];
const videoList_3 = [
  {
    id: 1,
    title: "Where is Ancient Philippi? Drive Thru History with Dave Stotts",
    duration: "5:29 mins",
    sourceLink: "https://www.youtube.com/embed/0sDamKS_vHU?si=AGfPvq6-N2IQ_QyF",
  },
  {
    id: 2,
    title: "Thessalonica - Footsteps of The Apostle Paul (Vlog 3/8)",
    duration: "4:19 mins",
    sourceLink: "https://www.youtube.com/embed/kFhfl4e5vQ8?si=gyVMPZ7W_p0M-svJ",
  },
  {
    id: 3,
    title: "Where is Ancient Thessalonica?",
    duration: "6:30 mins",
    sourceLink: "https://www.youtube.com/embed/O9Cc6fRbadk?si=EQb1su98qi8pAW2z",
  },
  {
    id: 4,
    title: "Who were the Ancient Bereans?",
    duration: "4:21 mins",
    sourceLink: "https://www.youtube.com/embed/3j4PIFagXJM?si=h4srBUvzy5JzZoQG",
  },
  {
    id: 5,
    title: "Did Paul really Visit Ancient Athens?",
    duration: "5:56 mins",
    sourceLink: "https://www.youtube.com/embed/WeohDhepxOM?si=RVvzXEBcBldwxqUt",
  },
  {
    id: 6,
    title: "What is the Story of Ancient Corinth?",
    duration: "5:15 mins",
    sourceLink: "https://www.youtube.com/embed/jiRM_FEqJmI?si=VYZzGyV8cU2h6OB_",
  },
  {
    id: 7,
    title: "How long did Paul live in Corinth?",
    duration: "5:08 mins",
    sourceLink: "https://www.youtube.com/embed/BWYeIaNsTPc?si=yQQ1BLR88PhSGdtv",
  },
];
const videoList_4 = [
  {
    id: 1,
    title:
      "Athens, Greece: Ancient Acropolis and Agora - Rick Steves’ Europe Travel Guide - Travel Bite",
    duration: "4:33 mins",
    sourceLink: "https://www.youtube.com/embed/xP-FsX0QW88?si=5bJa2H07XHTi_Lb-",
  },

  {
    id: 2,
    title: "History Summarized: The Acropolis",
    duration: "11:52 mins",
    sourceLink: "https://www.youtube.com/embed/9XvUjdTkJ3o?si=Jzpl6HP_AkDsgtEU",
  },
  {
    id: 3,
    title:
      "Virtual tour in ancient Athens (5th century BC) - 3D reconstruction",
    duration: "12:12 mins",
    sourceLink: "https://www.youtube.com/embed/ulAxMLJ7O7M?si=rsnJoG3Ob6Ecb3Ob",
  },
];
const videoList_5 = [
  {
    id: 1,
    title: "The Stoa Poikile — The Birthplace of Stoicism",
    duration: "3:11 mins",
    sourceLink: "https://www.youtube.com/embed/Ng-LWBUhTQ8?si=JMKrkxYR5pXFLI6R",
  },
  {
    id: 2,
    title: "Zeno of Citium | Founder Of Stoicism",
    duration: "7:00 mins",
    sourceLink: "https://www.youtube.com/embed/Gu0FrhJ3HE4?si=5vQV39ilOUwlws0g",
  },
];

const videoList_6 = [
  {
    id: 1,
    title: "Greco-Persian Wars (Complete) – Ancient History – See U in History",
    duration: "26:36 mins",
    sourceLink: "https://www.youtube.com/embed/qjz--53575E?si=kGdFlgDsaYdQysOb",
  },
  {
    id: 2,
    title:
      "The Battle of Marathon, 490 BC ⚔️ First Persian Invasion of Ancient Greece",
    duration: "13:08 mins",
    sourceLink: "https://www.youtube.com/embed/0TWYjEKiais?si=WkpoDZlfPV2nFgEG",
  },
  // {
  //   id: 3,
  //   title: "Persian Empire Vs Athens: The Historical Battle of Marathon 490 BC | Cinematic",
  //   duration: "15:39 mins",
  //   sourceLink: "https://www.youtube.com/embed/BvIk7UXGf54?si=guMr96Ud8Fk2xIkI"
  // },
  // {
  //   id: 4,
  //   title: "Battles BC: Judgement Day at Marathon (S1, E8) | Full Episode",
  //   duration: "45:18 mins",
  //   sourceLink: "https://www.youtube.com/embed/iQppm__s5hs"
  // },

  // {
  //   id: 5,
  //   title: "Leonidas & The 300 Spartans Documentary",
  //   duration: "1:06:05 mins",
  //   sourceLink: "https://www.youtube.com/embed/ax7E-3eftPs?si=P5_7RF9CFsum69PI"
  // },
  {
    id: 6,
    title:
      "The Battle of Thermopylae: How 300 Spartans Held Off Thousands of Persians | DOCUMENTARY",
    duration: "20:19 mins",
    sourceLink: "https://www.youtube.com/embed/BmFNoQkN23w?si=ICnHHtlZgdZvwicE",
  },
  {
    id: 7,
    title: "Battle of Salamis 480 BC (Persian Invasion of Greece) Documentary",
    duration: "11:06 mins",
    sourceLink: "https://www.youtube.com/embed/7uhoDZtA9PQ?si=Ob8o1yjzEd-imvuP",
  },
];
const videoList_7 = [
  // {
  //   id: 1,
  //   title: "Assassination of Julius Caesar: Why and How DOCUMENTARY",
  //   duration: "22:24 mins",
  //   sourceLink: "https://www.youtube.com/embed/ZVc2aJwWwbo?si=8or3Z8gsUWamq6U8"
  // },
  {
    id: 2,
    title: "The Assassination Of Julius Caesar Explained",
    duration: "11:28 mins",
    sourceLink: "https://www.youtube.com/embed/Fg_RWvTmrpw?si=lYK3mMYbHSLrOdOy",
  },
  // {
  //   id: 3,
  //   title: "What Happened to All the Roman Conspirators After Julius Caesar's Death?",
  //   duration: "13:48 mins",
  //   sourceLink: "https://www.youtube.com/embed/KlEG_VvmnZI?si=-Y_G486aqFqEnuk5"
  // },
  {
    id: 4,
    title: "Battle of Philippi - Post-Caesar Civil Wars DOCUMENTARY",
    duration: "20:47 mins",
    sourceLink: "https://www.youtube.com/embed/0B83Jk1qB9c?si=n0TsUCFtyhvxm6sN",
  },
  {
    id: 5,
    title: "Battle of Actium (31 BC) - Final War of the Roman Republic",
    duration: "14:41 mins",
    sourceLink: "https://www.youtube.com/embed/Q5KhUhN8geo?si=W16FXRQm6NHN9anK",
  },
  // {
  //   id: 6,
  //   title: "The Battle of Actium (31 B.C.E.)",
  //   duration: "22:29 mins",
  //   sourceLink: "https://www.youtube.com/embed/t7Y-1qMKY_0?si=W8yuKzJ84Qt3-jW8"
  // },
  // {
  //   id: 7,
  //   title: "The Best Documentary Ever - ACTIUM 31 BC",
  //   duration: "13:12 mins",
  //   sourceLink: "https://www.youtube.com/embed/QPO4ms1KdgY?si=ChNitnBoqdJXUi4x"
  // }
  {
    id: 7,
    title: "The Death of Antony and Cleopatra, 30 BC",
    duration: "26:07 mins",
    sourceLink: "https://www.youtube.com/embed/EezHXh-UG-c?si=Fw4u1fWZ2Ww6pgl6",
  },
];
const videoList_8 = [
  {
    id: 1,
    title: "The Erechtheion | Acropolis of Athens | Greece | 4K",
    duration: "11:34 mins",
    sourceLink: "https://www.youtube.com/embed/pJqsneLGv-g?si=Xb18RLK_326Og25I",
  },
];

function VideoStudy() {
  const [modal, setModal] = useState(false);
  const [videoLink, setVideoLink] = useState("");
  //const [videoLoading, setVideoLoading] = useState(true);

  const closeModal = () => {
    setModal(!modal);
  };

  return (
    <div className="pageContainer">
      <div className="title">Documentaries for the Greece Tour</div>
      <p className="text">
        You are invited/encouraged to watch these documentaries in the months
        prior to departure. It will add greatly to your experience/learning.
      </p>
      <div className={`secondaryTitle ${styles.videoGroupTitle}`}>
        Introduction to the country of Greece
      </div>
      {videoList_1.map((item, i) => (
        <button
          onClick={() => {
            setModal(!modal);
            setVideoLink(item.sourceLink);
          }}
          className={styles.videoTitle}
          key={i}
        >
          {item.title}
          <p>{item.duration}</p>
        </button>
      ))}

      <div className={`secondaryTitle ${styles.videoGroupTitle}`}>
        History of Greece
      </div>
      {videoList_2.map((item, i) => (
        <button
          onClick={() => {
            setModal(!modal);
            setVideoLink(item.sourceLink);
          }}
          className={styles.videoTitle}
          key={i}
        >
          {item.title}
          <p>{item.duration}</p>
        </button>
      ))}

      <div className={`secondaryTitle ${styles.videoGroupTitle}`}>
        Paul in Greece
      </div>
      {videoList_3.map((item, i) => (
        <button
          onClick={() => {
            setModal(!modal);
            setVideoLink(item.sourceLink);
          }}
          className={styles.videoTitle}
          key={i}
        >
          {item.title}
          <p>{item.duration}</p>
        </button>
      ))}
      <div className={`secondaryTitle ${styles.videoGroupTitle}`}>Athens</div>
      {videoList_4.map((item, i) => (
        <button
          onClick={() => {
            setModal(!modal);
            setVideoLink(item.sourceLink);
          }}
          className={styles.videoTitle}
          key={i}
        >
          {item.title}
          <p>{item.duration}</p>
        </button>
      ))}

      <div className={`secondaryTitle ${styles.videoGroupTitle}`}>
        Philosophy of the Stoics
      </div>
      {videoList_5.map((item, i) => (
        <button
          onClick={() => {
            setModal(!modal);
            setVideoLink(item.sourceLink);
          }}
          className={styles.videoTitle}
          key={i}
        >
          {item.title}
          <p>{item.duration}</p>
        </button>
      ))}
      <div className={`secondaryTitle ${styles.videoGroupTitle}`}>
        Greece’s Battles with the Persians
      </div>
      {videoList_6.map((item, i) => (
        <button
          onClick={() => {
            setModal(!modal);
            setVideoLink(item.sourceLink);
          }}
          className={styles.videoTitle}
          key={i}
        >
          {item.title}
          <p>{item.duration}</p>
        </button>
      ))}
      <div className={`secondaryTitle ${styles.videoGroupTitle}`}>
        The Roman Civil War Battles in Greece
      </div>
      {videoList_7.map((item, i) => (
        <button
          onClick={() => {
            setModal(!modal);
            setVideoLink(item.sourceLink);
          }}
          className={styles.videoTitle}
          key={i}
        >
          {item.title}
          <p>{item.duration}</p>
        </button>
      ))}
      <div className={`secondaryTitle ${styles.videoGroupTitle}`}>
        Misc docs
      </div>

      {videoList_8.map((item, i) => (
        <button
          onClick={() => {
            setModal(!modal);
            setVideoLink(item.sourceLink);
          }}
          className={styles.videoTitle}
          key={i}
        >
          {item.title}
          <p>{item.duration}</p>
        </button>
      ))}

      {modal ? (
        <VideoBox closeModal={closeModal} videoLink={videoLink} />
      ) : null}
    </div>
  );
}

export default VideoStudy;
