import scripture from "./../../img/Greece/GreeceTour_ScriptureReading.pdf";
import styles from "../../Styles/ScriptureGuide.module.scss";

function Scriptureguide() {
  return (
    <div className="pageContainer">
      <div className="title">Scripture Reading</div>
      <p className="secondaryTitle">Greece Tour</p>
      <div className="printBox">
        <div className="print">
          <a href={scripture} target="_blank" rel="noreferrer">
            Print PDF
          </a>
        </div>
      </div>
      <p className="text">
        It is very important for this trip that you read carefully Acts 15:30 -
        21:17. These chapters cover Paul’s second and third missionary journeys
        and the time he spent in Greece on those journeys. Follow a map of
        Paul’s second and third missionary journeys as you read Acts 15:30 -
        21:17. Most Bibles have a map with these journeys in the back of the
        Bible. Another option would be to google a map of Paul’s journeys.
      </p>
      <p className={styles.paragraphTitle}>
        These are stops in Greece on Paul’s Second Missionary Journey:
      </p>
      <p className="text">
        {" "}
        <span className={styles.city}>Philippi:</span> Acts 16:6-40, book of
        Philippians
      </p>
      <p className="text">
        {" "}
        <span className={styles.city}>Thessaloniki:</span> Acts 17:1-9, books of
        I & II Thessalonians
      </p>
      <p className="text">
        {" "}
        <span className={styles.city}>Berea:</span> Acts 17:10-15
      </p>
      <p className="text">
        {" "}
        <span className={styles.city}>Athens:</span> Acts 17:16-34
      </p>
      <p className="text">
        {" "}
        <span className={styles.city}>Corinth:</span> Acts 18:1-23, books of I &
        II Corinthians
      </p>
    </div>
  );
}

export default Scriptureguide;
