import { BrowserRouter, Route, Routes } from "react-router-dom";
import Main from "./Main";
import PageInMaking from "./Components/PageInMaking";

//Israel May files
import IsraelTrip from "./Israel/Layout/IsraelTrip";
import IsraelHomepage from "./Israel/Pages/IsraelHomepage";
import IsraelItinerary from "./Israel/Pages/IsraelItinerary";
import IsraelGeneralinfo from "./Israel/Pages/IsraelGeneralinfo";
import IsraelLastadvice from "./Israel/Pages/IsraelLastadvice";
import IsraelPackinglist from "./Israel/Pages/IsraelPackinglist";
import IsraelPrayer from "./Israel/Pages/IsraelPrayer";
import IsraelScriptureguide from "./Israel/Pages/IsraelScriptureguide";
import IsraelVideoStudy from "./Israel/Pages/IsraelVideoStudy";
import IsraelGalery from "./Israel/Pages/IsraelGalery";

// Petra Israel trip files
import PetraIsraelTrip from "./PetraIsrael/Layout/PetraIsraelTrip";
import PetraIsraelHomepage from "./PetraIsrael/Pages/PetraIsraelHomepage";
import PetraIsraelItinerary from "./PetraIsrael/Pages/PetraIsraelItinerary";
import PetraIsraelGeneralinfo from "./PetraIsrael/Pages/PetraIsraelGeneralinfo";
import PetraIsraelTravelAdvice from "./PetraIsrael/Pages/PetraIsraelLastadvice";
import PetraIsraelPackingList from "./PetraIsrael/Pages/PetraIsraelPackinglist";
import PetraIsraelPrayer from "./PetraIsrael/Pages/PetraIsraelPrayer";
import PetraIsraelScriptureGuide from "./PetraIsrael/Pages/PetraIsraelScriptureguide";
import PetraIsraelVideoStudy from "./PetraIsrael/Pages/PetraIsraelVideoStudy";
import PetraIsraelGallery from "./PetraIsrael/Pages/PetraIsraelGallery";

/*Greece files*/
import GreeceTrip from "./Greece/Layout/GreeceTrip";
import GreeceHomepage from "./Greece/Pages/GreeceHomepage";
import GreeceGeneralinfo from "./Greece/Pages/GreeceGeneralinfo";
import GreeceItinerary from "./Greece/Pages/GreeceItinerary";
import GreeceLastadvice from "./Greece/Pages/GreeceLastadvice";
import GreecePackinglist from "./Greece/Pages/GreecePackinglist";
import GreecePrayer from "./Greece/Pages/GreecePrayer";
import GreeceScriptureguide from "./Greece/Pages/GreeceScriptureguide";
import GreeceVideoStudy from "./Greece/Pages/GreeceVideoStudy";
import GreeceGalery from "./Greece/Pages/GreeceGalery";

// Italy trip files
import ItalyTrip from "./Italy/Layout/ItalyTrip";
import ItalyHomepage from "./Italy/Pages/ItalyHomepage";
import ItalyGeneralinfo from "./Italy/Pages/ItalyGeneralinfo";
import ItalyItinerary from "./Italy/Pages/ItalyItinerary";
import ItalyLastadvice from "./Italy/Pages/ItalyLastadvice";
import ItalyPackinglist from "./Italy/Pages/ItalyPackinglist";
import ItalyPrayer from "./Italy/Pages/ItalyPrayer";
import ItalyScriptureguide from "./Italy/Pages/ItalyScriptureguide";
import ItalyVideoStudy from "./Italy/Pages/ItalyVideoStudy";
import ItalyGallery from "./Italy/Pages/ItalyGallery";

//Poland trip files
import PolandTrip from "./Poland/Layout/PolandTrip";
import PolandHomepage from "./Poland/Pages/PolandHomepage";
import PolandGeneralinfo from "./Poland/Pages/PolandGeneralinfo";
import PolandItinerary from "./Poland/Pages/PolandItinerary";
import PolandLastadvice from "./Poland/Pages/PolandLastadvice";
import PolandPackinglist from "./Poland/Pages/PolandPackinglist";
import PolandPrayer from "./Poland/Pages/PolandPrayer";
import PolandScriptureguide from "./Poland/Pages/PolandScriptureguide";
import PolandVideoStudy from "./Poland/Pages/PolandVideoStudy";
import PolandGallery from "./Poland/Pages/PolandGallery";
import { useState } from "react";

//Switzerland trip files
import SwitzTrip from "./Switzerland/Layout/SwitzTrip";
import SwitzHomepage from "./Switzerland/Pages/SwitzHomepage";
import SwitzGeneralinfo from "./Switzerland/Pages/SwitzGeneralinfo";
import SwitzItinerary from "./Switzerland/Pages/SwitzItinerary";
import SwitzLastadvice from "./Switzerland/Pages/SwitzLastadvice";
import SwitzPackinglist from "./Switzerland/Pages/SwitzPackinglist";
import SwitzPrayer from "./Switzerland/Pages/SwitzPrayer";
import SwitzScriptureguide from "./Switzerland/Pages/SwitzScriptureguide";
import SwitzVideoStudy from "./Switzerland/Pages/SwitzVideoStudy";
import SwitzGallery from "./Switzerland/Pages/SwitzGallery";

//Turkey trip files
import TurkeyTrip from "./Turkey/Layout/TurkeyTrip";
import TurkeyHomepage from "./Turkey/Pages/TurkeyHomepage";
import TurkeyGeneralinfo from "./Turkey/Pages/TurkeyGeneralinfo";
import TurkeyItinerary from "./Turkey/Pages/TurkeyItinerary";
import TurkeyLastadvice from "./Turkey/Pages/TurkeyLastadvice";
import TurkeyPackinglist from "./Turkey/Pages/TurkeyPackinglist";
import TurkeyPrayer from "./Turkey/Pages/TurkeyPrayer";
import TurkeyScriptureguide from "./Turkey/Pages/TurkeyScriptureguide";
import TurkeyVideoStudy from "./Turkey/Pages/TurkeyVideoStudy";
import TurkeyGallery from "./Turkey/Pages/TurkeyGallery";

//England trip files
import EnglandTrip from "./England/Layout/EnglandTrip";
import EnglandHomepage from "./England/Pages/EnglandHomepage";
import EnglandGeneralinfo from "./England/Pages/EnglandGeneralinfo";
import EnglandItinerary from "./England/Pages/EnglandItinerary";
import EnglandLastAdvice from "./England/Pages/EnglandLastadvice";
import EnglandPackingList from "./England/Pages/EnglandPackinglist";
import EnglandPrayer from "./England/Pages/EnglandPrayer";
import EnglandVideoStudy from "./England/Pages/EnglandVideoStudy";
import EnglandGallery from "./England/Pages/EnglandGallery";

//Germany trip files

import GermanyTrip from "./Germany/Layout/GermanyTrip";
import GermanyHomepage from "./Germany/Pages/GermanyHomepage";
import GermanyGeneralinfo from "./Germany/Pages/GermanyGeneralinfo";
import GermanyItinerary from "./Germany/Pages/GermanyItinerary";
import GermanyLastAdvice from "./Germany/Pages/GermanyLastadvice";
import GermanyPackingList from "./Germany/Pages/GermanyPackinglist";
import GermanyPrayer from "./Germany/Pages/GermanyPrayer";
import GermanyVideoStudy from "./Germany/Pages/GermanyVideoStudy";
import GermanyGallery from "./Germany/Pages/GermanyGallery";

function App() {
  // const [trip, setTrip] = useState("");
  // console.log(trip);
  return (
    <div>
      <BrowserRouter>
        <Routes>
          {/*Routs to Israel 05-24*/}
          <Route path="/" element={<Main />} />
          <Route path="pageInMaking" element={<PageInMaking />} />
          <Route path="Israel-05-24" element={<IsraelTrip />}>
            <Route index element={<IsraelHomepage />} />
            <Route path="Itinerary" element={<IsraelItinerary />} />
            <Route path="Generalinfo" element={<IsraelGeneralinfo />} />
            <Route path="TravelAdvice" element={<IsraelLastadvice />} />
            <Route path="PackingList" element={<IsraelPackinglist />} />
            <Route path="Prayer" element={<IsraelPrayer />} />
            <Route path="ScriptureGuide" element={<IsraelScriptureguide />} />
            <Route path="VideoStudy" element={<IsraelVideoStudy />} />
            <Route path="Gallery" element={<IsraelGalery />} />
          </Route>

          {/*Routs to Petra Israel 04-24*/}
          <Route path="/" element={<Main />} />
          <Route path="pageInMaking" element={<PageInMaking />} />
          <Route path="Israel-Petra-04-24" element={<PetraIsraelTrip />}>
            <Route index element={<PetraIsraelHomepage />} />
            <Route path="Itinerary" element={<PetraIsraelItinerary />} />
            <Route path="Generalinfo" element={<PetraIsraelGeneralinfo />} />
            <Route path="TravelAdvice" element={<PetraIsraelTravelAdvice />} />
            <Route path="PackingList" element={<PetraIsraelPackingList />} />
            <Route path="Prayer" element={<PetraIsraelPrayer />} />
            <Route
              path="ScriptureGuide"
              element={<PetraIsraelScriptureGuide />}
            />
            <Route path="VideoStudy" element={<PetraIsraelVideoStudy />} />
            <Route path="Gallery" element={<PetraIsraelGallery />} />
          </Route>

          {/*routes to Greece */}
          <Route path="Greece-april-2025" element={<GreeceTrip />}>
            <Route index element={<GreeceHomepage />} />
            <Route path="Generalinfo" element={<GreeceGeneralinfo />} />
            <Route path="Itinerary" element={<GreeceItinerary />} />

            <Route path="TravelAdvice" element={<GreeceLastadvice />} />
            <Route path="PackingList" element={<GreecePackinglist />} />
            <Route path="Prayer" element={<GreecePrayer />} />
            <Route path="Gallery" element={<GreeceGalery />} />

            <Route path="ScriptureGuide" element={<GreeceScriptureguide />} />
            <Route path="VideoStudy" element={<GreeceVideoStudy />} />
          </Route>

          {/* Routs to Italy */}
          <Route path="Italy-April-2024" element={<ItalyTrip />}>
            <Route index element={<ItalyHomepage />} />
            <Route path="Generalinfo" element={<ItalyGeneralinfo />} />
            <Route path="Itinerary" element={<ItalyItinerary />} />
            <Route path="TravelAdvice" element={<ItalyLastadvice />} />
            <Route path="PackingList" element={<ItalyPackinglist />} />
            <Route path="Prayer" element={<ItalyPrayer />} />
            <Route path="Gallery" element={<ItalyGallery />} />

            <Route path="ScriptureGuide" element={<ItalyScriptureguide />} />
            <Route path="VideoStudy" element={<ItalyVideoStudy />} />
          </Route>

          {/* Routs to Poland */}
          <Route path="Poland-May-2024" element={<PolandTrip />}>
            <Route index element={<PolandHomepage />} />
            <Route path="Generalinfo" element={<PolandGeneralinfo />} />
            <Route path="Itinerary" element={<PolandItinerary />} />
            <Route path="TravelAdvice" element={<PolandLastadvice />} />
            <Route path="PackingList" element={<PolandPackinglist />} />
            <Route path="Prayer" element={<PolandPrayer />} />
            <Route path="Gallery" element={<PolandGallery />} />

            <Route path="ScriptureGuide" element={<PolandScriptureguide />} />
            <Route path="VideoStudy" element={<PolandVideoStudy />} />
          </Route>

          {/* Routs to Switzerland */}
          <Route path="Switzerland/:trip" element={<SwitzTrip />}>
            <Route index element={<SwitzHomepage />} />
            <Route path="Generalinfo" element={<SwitzGeneralinfo />} />
            <Route path="Itinerary" element={<SwitzItinerary />} />
            <Route path="TravelAdvice" element={<SwitzLastadvice />} />
            <Route path="PackingList" element={<SwitzPackinglist />} />
            <Route path="Prayer" element={<SwitzPrayer />} />
            <Route path="Gallery" element={<SwitzGallery />} />

            <Route path="ScriptureGuide" element={<SwitzScriptureguide />} />
            <Route path="VideoStudy" element={<SwitzVideoStudy />} />
          </Route>

          {/* Routs to Turkey */}
          <Route path="Turkey-Fall-2024" element={<TurkeyTrip />}>
            <Route index element={<TurkeyHomepage />} />
            <Route path="Generalinfo" element={<TurkeyGeneralinfo />} />
            <Route path="Itinerary" element={<TurkeyItinerary />} />
            <Route path="TravelAdvice" element={<TurkeyLastadvice />} />
            <Route path="PackingList" element={<TurkeyPackinglist />} />
            <Route path="Prayer" element={<TurkeyPrayer />} />
            <Route path="Gallery" element={<TurkeyGallery />} />

            <Route path="ScriptureGuide" element={<TurkeyScriptureguide />} />
            <Route path="VideoStudy" element={<TurkeyVideoStudy />} />
          </Route>

          {/* Routs to England trip */}
          <Route path="England-Fall-2024" element={<EnglandTrip />}>
            <Route index element={<EnglandHomepage />} />
            <Route path="Generalinfo" element={<EnglandGeneralinfo />} />
            <Route path="Itinerary" element={<EnglandItinerary />} />
            <Route path="TravelAdvice" element={<EnglandLastAdvice />} />
            <Route path="PackingList" element={<EnglandPackingList />} />
            <Route path="Prayer" element={<EnglandPrayer />} />
            <Route path="Gallery" element={<EnglandGallery />} />
            <Route path="VideoStudy" element={<EnglandVideoStudy />} />
          </Route>

          {/* Routs to Germany trip */}
          <Route path="Germany-Spring-2025" element={<GermanyTrip />}>
            <Route index element={<GermanyHomepage />} />
            <Route path="Generalinfo" element={<GermanyGeneralinfo />} />
            <Route path="Itinerary" element={<GermanyItinerary />} />
            <Route path="TravelAdvice" element={<GermanyLastAdvice />} />
            <Route path="PackingList" element={<GermanyPackingList />} />
            <Route path="Prayer" element={<GermanyPrayer />} />
            <Route path="Gallery" element={<GermanyGallery />} />
            <Route path="VideoStudy" element={<GermanyVideoStudy />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
